import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Link } from 'gatsby'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonArea: {
      margin: '0 auto',
      paddingBottom: theme.spacing(2),
    },
    content: {
      paddingBottom: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
      paddingTop: theme.spacing(2),
    },
    header: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      textAlign: 'center',
    },
    link: {
      textDecoration: 'none',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  }),
)

interface ServiceItemProps {
  title: string
  description: string
  buttonText: string
  slug: string
}

const ServiceItem: FC<ServiceItemProps> = ({
  title,
  description,
  buttonText,
  slug,
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.root} elevation={5}>
      <CardActionArea>
        <Link className={classes.link} to={slug}>
          <CardHeader
            className={classes.header}
            title={title}
            titleTypographyProps={{
              style: {
                lineHeight: '1.5rem',
                whiteSpace: 'pre-line',
              },
              variant: 'h4',
            }}
          />
        </Link>
      </CardActionArea>
      <CardContent className={classes.content}>
        <Typography align="center" color="textPrimary" paragraph>
          {description}
        </Typography>
      </CardContent>
      <CardActions className={classes.buttonArea}>
        <Link className={classes.link} to={slug}>
          <Button color="secondary" size="large" variant="outlined">
            {buttonText}
          </Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default ServiceItem
