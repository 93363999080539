import { createStyles, makeStyles } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { FC } from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      boxShadow: '0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
)

const AboutMePhoto: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "about-me.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  const classes = useStyles()

  return (
    <Img
      className={classes.image}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  )
}

export default AboutMePhoto
