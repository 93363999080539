import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'

import { PageProps } from '../../types/types'
import AboutMe from '../components/about-me/AboutMe'
import Blog from '../components/blog/Blog'
import Contact from '../components/contact/Contact'
import Jumbotron from '../components/Jumbotron'
import Pricing from '../components/Pricing'
import Seo from '../components/seo/Seo'
import Services from '../components/services/Services'
import PageLayout from '../components/shared/PageLayout'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    evenSection: {
      backgroundColor: theme.palette.grey[200],
    },
  }),
)

const IndexPage: FC<PageProps> = ({ location }) => {
  const classes = useStyles()

  return (
    <PageLayout pathname={location.pathname}>
      <Seo pathname={location.pathname} />
      <Grid component="main" container direction="column">
        <Grid component="article" item>
          <Jumbotron />
        </Grid>
        <Grid component="article" id="o-mnie" item>
          <AboutMe />
        </Grid>
        <Grid
          className={classes.evenSection}
          component="article"
          id="oferta"
          item
        >
          <Services />
        </Grid>
        <Grid component="article" id="cennik" item>
          <Pricing />
        </Grid>
        <Grid
          className={classes.evenSection}
          component="article"
          id="blog"
          item
        >
          <Blog />
        </Grid>
        <Grid component="article" id="kontakt" item>
          <Contact />
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default IndexPage
