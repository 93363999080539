import { Card } from '@material-ui/core'
import CardMedia from '@material-ui/core/CardMedia'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    media: {
      border: 0,
    },
  }),
)

interface AddressMapProps {
  height: number
  src: string
  title: string
}

const AddressMap: FC<AddressMapProps> = ({ height, src, title }) => {
  const classes = useStyles()

  return (
    <Card elevation={2} square>
      <CardMedia
        className={classes.media}
        component="iframe"
        height={height}
        src={src}
        title={title}
      />
    </Card>
  )
}

export default AddressMap
