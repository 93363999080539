import { Typography } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

import HtmlAstComponent from '../shared/HtmlAstComponent'
import TextContainer from '../shared/TextContainer'

const Paragraph: FC = ({ children }) => {
  return <Typography paragraph>{children}</Typography>
}

const AboutMeDescription: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { tag: { eq: "about-me" } }) {
        htmlAst
      }
    }
  `)

  return (
    <TextContainer>
      <HtmlAstComponent
        componentsMapping={{
          p: Paragraph,
        }}
        htmlAst={data.markdownRemark.htmlAst}
      />
    </TextContainer>
  )
}

export default AboutMeDescription
