import { Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

import HtmlAstComponent from './shared/HtmlAstComponent'
import SectionLayout from './shared/SectionLayout'

const Paragraph: FC = ({ children }) => {
  return <Typography paragraph>{children}</Typography>
}

const Pricing: FC = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { tag: { eq: "pricing" } }) {
        htmlAst
      }
    }
  `)

  return (
    <SectionLayout mainTitle={'CENNIK'}>
      <Container>
        <HtmlAstComponent
          componentsMapping={{ p: Paragraph }}
          htmlAst={data.markdownRemark.htmlAst}
        />
      </Container>
    </SectionLayout>
  )
}

export default Pricing
