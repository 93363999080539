import { Box, Paper, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.grey[400],
      fontSize: '3rem',
      margin: '0 auto',
    },
    mainContent: {
      lineHeight: '1.5',
      marginBottom: theme.spacing(4),
      whiteSpace: 'pre-line',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5),
      },
      width: '100%',
    },
    title: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  }),
)

interface ContactItemProps {
  icon: React.ReactElement
  title: string
  content: string | React.ReactElement
  extraContent?: string | React.ReactElement
}

const ContactItem: FC<ContactItemProps> = ({ ...props }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={5}>
      <Box className={classes.icon}>{props.icon}</Box>
      <Typography
        align="center"
        className={classes.title}
        color="secondary"
        variant="h3"
      >
        {props.title}
      </Typography>
      <Typography
        align="center"
        className={classes.mainContent}
        color="textSecondary"
        variant="h4"
      >
        {props.content}
      </Typography>
      {props.extraContent && (
        <Typography align="center" paragraph variant="caption">
          {props.extraContent}
        </Typography>
      )}
    </Paper>
  )
}

export default ContactItem
