import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      flexDirection: 'column',
    },
    card: {
      display: 'flex',
    },
    media: {
      height: 140,
    },
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textDecoration: 'none',
    },
  }),
)

interface PostCardProps {
  title: string
  description: string
  cover: FluidObject
  slug: string
}

const PostCard: FC<PostCardProps> = ({ title, description, cover, slug }) => {
  const classes = useStyles()

  return (
    <Link className={classes.root} to={slug}>
      <Card className={classes.card}>
        <CardActionArea className={classes.action}>
          <Img className={classes.media} fluid={cover} />
          <CardContent>
            <Typography align="center" component="h2" gutterBottom variant="h5">
              {title}
            </Typography>
            <Typography align="justify" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  )
}

export default PostCard
