import Grid from '@material-ui/core/Grid'
import React, { FC } from 'react'

import SectionLayout from '../shared/SectionLayout'
import AboutMeDescription from './AboutMeDescription'
import AboutMePhoto from './AboutMePhoto'

const AboutMe: FC = () => {
  return (
    <SectionLayout
      mainTitle={'BARBARA KOŚCIELNIAK-RAPACZ'}
      subTitle={'psychoterapeuta poznawczo-behawioralny, psycholog, pedagog'}
    >
      <Grid container spacing={4}>
        <Grid item md={4} xs>
          <AboutMePhoto />
        </Grid>
        <Grid item md={8}>
          <AboutMeDescription />
        </Grid>
      </Grid>
    </SectionLayout>
  )
}

export default AboutMe
