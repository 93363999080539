import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

import AddressLink from '../shared/AddressLink'
import EmailLink from '../shared/EmailLink'
import PhoneLink from '../shared/PhoneLink'
import SectionLayout from '../shared/SectionLayout'
import AddressMap from './AddressMap'
import ContactList from './ContactList'

const CONTACTS = [
  {
    content: <PhoneLink />,
    extraContent:
      'Ze względu na charakter pracy nie zawsze jestem w stanie odebrać telefon. W takiej sytuacji oddzwonię najszybciej jak to będzie możliwe.',
    icon: <FontAwesomeIcon icon={faPhone} />,
    title: 'Telefon',
  },
  {
    content: <EmailLink />,
    icon: <FontAwesomeIcon icon={faEnvelope} />,
    title: 'Email',
  },
  {
    content: <AddressLink center />,
    icon: <FontAwesomeIcon icon={faHome} />,
    title: 'Adres',
  },
]

const MAP_URL = `https://www.google.com/maps/embed/v1/place?q=place_id:${process.env.GATSBY_GOOGLE_MAPS_PLACE_ID}&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`

const Contact: FC = () => {
  const {
    site: {
      siteMetadata: { siteName },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
        }
      }
    }
  `)

  return (
    <SectionLayout mainTitle={'KONTAKT'}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ContactList contacts={CONTACTS} />
        </Grid>
        <Grid item xs={12}>
          <AddressMap height={400} src={MAP_URL} title={siteName} />
        </Grid>
      </Grid>
    </SectionLayout>
  )
}

export default Contact
