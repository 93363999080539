import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

import SectionLayout from '../shared/SectionLayout'
import PostCard from './PostCards'

const useStyles = makeStyles(() =>
  createStyles({
    gridItem: {
      display: 'flex',
    },
  }),
)

const Blog: FC = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { template: { eq: "post" }, cover: {} } }
        sort: { fields: frontmatter___date }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              description
              cover {
                relativePath
                childImageSharp {
                  fluid(maxHeight: 140, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges

  return (
    <SectionLayout mainTitle={'BLOG'}>
      <Grid container justify="center" spacing={4}>
        {posts.map((post) => {
          const {
            title,
            description,
            cover: {
              childImageSharp: { fluid: coverImage },
            },
          } = post.node.frontmatter

          const slug = post.node.fields.slug

          return (
            <Grid className={classes.gridItem} item key={title} md={6}>
              <PostCard
                cover={coverImage}
                description={description}
                slug={slug}
                title={title}
              />
            </Grid>
          )
        })}
      </Grid>
    </SectionLayout>
  )
}

export default Blog
