import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'

import ContactItem from './ContactItem'

interface ContactListItem {
  icon: React.ReactElement
  title: string
  content: string | React.ReactElement
  extraContent?: string | React.ReactElement
}

interface ContactListProps {
  contacts: ContactListItem[]
}

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '600px',
      },
    },
  }),
)

const ContactList: FC<ContactListProps> = ({ contacts }) => {
  const classes = useStyles()

  return (
    <Grid alignItems="stretch" container justify="center" spacing={4}>
      {contacts.map((c) => (
        <Grid className={classes.gridItem} item key={c.title} lg={4} xs={12}>
          <ContactItem {...c} />
        </Grid>
      ))}
    </Grid>
  )
}

export default ContactList
