import { Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 100px)',
      justifyContent: 'center',
    },
    title: {
      color: theme.palette.common.white,
    },
  }),
)

const Jumbotron: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "main-photo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const classes = useStyles()

  return (
    <BackgroundImage
      className={classes.image}
      fluid={data.placeholderImage.childImageSharp.fluid}
    >
      <Typography align="center" className={classes.title} variant={'h1'}>
        {'Gabinet Psychoterapii i Rozwoju Osobistego'}
      </Typography>
      <Typography align="center" className={classes.title} variant={'h2'}>
        {'Barbara Kościelniak-Rapacz'}
      </Typography>
    </BackgroundImage>
  )
}

export default Jumbotron
