import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'

import ServiceItem from './ServiceItem'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '600px',
      },
    },
  }),
)

const ServiceList: FC = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query ServiceList {
      allMarkdownRemark(
        filter: { frontmatter: { template: { eq: "services" } } }
        sort: { fields: frontmatter___order }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              order
              description
            }
          }
        }
      }
    }
  `)

  const services = data.allMarkdownRemark.edges

  return (
    <Grid container justify="center" spacing={4}>
      {services.map((service) => {
        const { title, order, description } = service.node.frontmatter
        const slug = service.node.fields.slug

        return (
          <Grid className={classes.gridItem} item key={order} lg={4}>
            <ServiceItem
              buttonText={'DOWIEDZ SIĘ WIĘCEJ'}
              description={description}
              slug={slug}
              title={title}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ServiceList
