import React from 'react'

import SectionLayout from '../shared/SectionLayout'
import ServiceList from './ServiceList'

const HEADER_TEXT = {
  mainTitle: 'OFERTA',
}

const Services = () => {
  return (
    <SectionLayout mainTitle={HEADER_TEXT.mainTitle}>
      <ServiceList />
    </SectionLayout>
  )
}

export default Services
